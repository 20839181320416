import React, { useState, useEffect } from 'react';
import axios from 'axios';

let ip = "45.79.30.106:8000"
let hostname = window.location.hostname.toString();
if (hostname.indexOf("localhost") !== -1)
  ip = "localhost:8000"

export const RunQuery = () => {
  const [query, setQuery] = React.useState('');
  const [csrfToken, setCsrfToken] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [keyValue, setKeyValue] = useState('');

  const handleKeyChange = (event) => {
    setKeyValue(event.target.value);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    axios.get('http://'+ip+'/get_csrf_token/')
      .then(response => setCsrfToken(response.data.csrfToken))
      .catch(error => console.error('Error fetching CSRF token:', error));
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    // const query = "What pitcher, give his name, had the lowest SIERA, and what was it?"
    console.log(inputValue);
    const response = await axios.post('http://'+ip+'/query/', 
    {
      question: inputValue, 
      key: keyValue
    }, 
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken // Include CSRF token in headers
      }
    });
    console.log(response.data);
    setQuery(response.data);
  }

  return (
    <div>
      <h2>108 Zone MLB Data Analyzer</h2>
      <div>&nbsp;</div>
      <div>OpenAI Key - you should definitely NOT put your key in here</div>
      <div>&nbsp;</div>
      <div>
        <input onChange={handleKeyChange} style={{width:800}} />
      </div>
      <div>&nbsp;</div>
      <h2>What is you question?</h2>
      <div>For example "What pitcher, give his name, had the lowest SIERA, and what was it?"</div>
      <div>&nbsp;</div>
      <div>
        <textarea onChange={handleInputChange} style={{width:800,height:100}}/>
      </div>
      <div>
        <button onClick={onSubmit}>Run Query</button>
      </div>
      <div>&nbsp;</div>
      <div>
        {!query && <div>No results</div>}
        {query && query.message &&
        <div>
          <div>{query.message.output}</div>
          <div>&nbsp;</div>
          <h2><a href="https://github.com/explodinggradients/ragas" target="_blank">Ragas</a> Test Results</h2>
          <div>&nbsp;</div>
          <div>Faithfulness: {query.message.score.faithfulness}</div>
          <div>Answer Correctness: {query.message.score.answer_correctness}</div>
        </div>
        }
      </div>
    </div>
  );
}

